import React from "react";
import { DateField, TextFieldProps } from "react-admin";
import AndroidIcon from '@material-ui/icons/Android';
import UnknownIcon from '@material-ui/icons/DeviceUnknown';

class DeviceTypeComponent extends React.Component<TextFieldProps> {
    render() {
        let value: string = this.props.record![this.props.source!];
        if (value === "android") {
            return <AndroidIcon/>;
        } else {
            return <UnknownIcon/>;
        }
    }
}

export default DeviceTypeComponent;