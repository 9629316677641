import React from "react";
import { DateField, DateFieldProps } from "react-admin";

class TimeStampDateField extends React.Component<DateFieldProps> {
    
    render() {
        let fixedRecord = this.props.record;
        let value = this.props.record![this.props.source!];
        const date = value instanceof Date ? value : new Date(value * 1000);
        fixedRecord![ this.props.source! ] = date;
        return  <DateField {...this.props} record={fixedRecord} showTime={this.props.showTime}/>
    }
}

export default TimeStampDateField;