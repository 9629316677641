import * as React from "react";
import { Admin, Resource, ListGuesser } from 'react-admin';
import authProvider from './providers/authProvider'
import AppDataProvider from './providers/dataProvider'
import DevicesList from "./resources/devices/list";
import QuickAddDeviceButton from './resources/devices/QuickAddDeviceButton'

import { createTheme } from '@material-ui/core/styles';

import { defaultTheme } from 'react-admin';



//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');
const dataProvider = new AppDataProvider();



//   const theme = createTheme({
//     palette,
//       shape,
//       typography,
//       shadows,
//       customShadows
//   });
let themeStr = `{"palette":{"common":{"black":"#000","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"#7986cb","main":"rgba(202, 207, 234, 1)","dark":"#303f9f","contrastText":"#fff"},"secondary":{"light":"#ff4081","main":"rgba(100, 92, 95, 1)","dark":"#c51162","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(107, 56, 56, 0.87)","secondary":"rgba(97, 9, 9, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}}`;
// const theme = createTheme(JSON.parse(themeStr));
const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: '#264653',
        },
        secondary: {
          main: '#2A9D8F',
        },
        error: {
          main: '#E76F51',
        },
        warning: {
          main: '#F4A261',
        },
        info: {
          main: '#E9C46A',
        },
      },

});
  
const App = () => (
    <Admin 
            theme={theme}
           dataProvider={dataProvider}
           authProvider={authProvider}>

        <Resource name="users" list={ListGuesser}/>
        <Resource name="devices" list={DevicesList} create={QuickAddDeviceButton} />

    </Admin>
);
export default App;