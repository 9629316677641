import { AppConfig } from "../config"
import { CreateParams, CreateResult, DataProvider, DeleteManyParams, DeleteParams, GetListParams, GetManyParams, GetManyReferenceParams, GetOneParams, HttpError, Record, UpdateManyParams, UpdateParams } from 'react-admin';
import { stringify } from 'query-string';

import { fetchUtils } from 'react-admin';

export default class AppDataProvider implements DataProvider {

    httpClient = (url:string, options?: fetchUtils.Options) => {
        if (!options || !options.headers) {
            if (!options) {
                options = {};
            }
            options.headers = new Headers({ Accept: 'application/json' });
        }
        options.credentials = "include";
        let authToken = localStorage.getItem('authToken');
        if (authToken) {
            const token = JSON.parse( authToken );
            (options.headers as Headers).set('Authorization', `Bearer ${token}`);
        }
        return fetchUtils.fetchJson(url, options);
    };

    async getList(resource: string, params: GetListParams) {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${AppConfig.apiUrl}/api/${resource}?${stringify(query)}`;

        return this.httpClient(url).then(({ headers, json }) => ({
            data: json,
            // total: parseInt(((headers.get('content-range') || '').split('/').pop())!, 10),
            total: json.length || 0
        }));
    };

    async getOne(resource: string, params: GetOneParams) {
        return this.httpClient(`${AppConfig.apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        }))
    };

    async getMany(resource: string, params: GetManyParams) {
        const query = {
            filter: JSON.stringify({ ids: params.ids }),
        };
        const url = `${AppConfig.apiUrl}/${resource}?${stringify(query)}`;
        return this.httpClient(url).then(({ json }) => ({ data: json }));
    }

    async getManyReference(resource: string, params: GetManyReferenceParams) {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${AppConfig.apiUrl}/${resource}?${stringify(query)}`;

        return this.httpClient(url).then(({ headers, json }) => {
            return {
                data: json,
                total: parseInt(((headers.get('content-range') || '').split('/').pop())! , 10),
            };
        });

    }

    async update(resource: string, params: UpdateParams) {
        return this.httpClient(`${AppConfig.apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    }

    async updateMany(resource: string, params: UpdateManyParams) {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return this.httpClient(`${AppConfig.apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    }

    async create(resource: string, params: CreateParams) {
        return this.httpClient(`${AppConfig.apiUrl}/api/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then( ({ status, json}) => {
            console.error(status, json);

            if (status < 200 || status >= 300) {
                console.error("this is it");
                return Promise.reject(
                    new HttpError(
                        (json && json.message) || "eee",
                        status,
                        json
                    )
                );
            }
            return {
                data: { ...params.data, id: json.id },
            }
        })
        
        
        // .then(({ json }) => ({
        //     data: { ...params.data, id: json.id },
        // }))
    }

    async delete(resource: string, params: DeleteParams) {
        return this.httpClient(`${AppConfig.apiUrl}/api/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }))
    }

    async deleteMany(resource: string, params: DeleteManyParams) {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return this.httpClient(`${AppConfig.apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.ids),
        }).then(({ json }) => ({ data: json }));
    }
}

// const fetchJson = (url, options = {}) => {
//     options.user = {
//         authenticated: true,
//         token: 'SRTRDFVESGNJYTUKTYTHRG'
//     };
//     return fetchUtils.fetchJson(url, options);
// };
// const dataProvider = simpleRestProvider('http://path.to.my.api/', fetchJson);
