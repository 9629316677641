import React, { cloneElement } from 'react';
import {
  Button,
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  List,
  ListActions,
  ListActionsProps,
  ResourceComponentProps,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  TopToolbar
} from 'react-admin';
import DeviceTypeComponent from '../../components/DeviceTypeField';
import TimeStampDateField from '../../components/TimeStampDateField';
import QuickAddDeviceButton from './QuickAddDeviceButton';

const DevicesListActions = (props: ListActionsProps) => (
    <TopToolbar>
        {props.filters
                    ? cloneElement(props.filters!, { context: 'button' })
                    : props.filters && <FilterButton />}
        <QuickAddDeviceButton/>
        {/* <ExportButton/> */}
        
    </TopToolbar>
);

class DevicesList extends React.Component<ResourceComponentProps> {

  render() {
    return (
      <List {...this.props} actions={<DevicesListActions/>} >
        <Responsive
          small={
            <SimpleList
              linkType="show"
              primaryText={record => record.name || record.id}
              tertiaryText={record => {
                  const date = record.added_on instanceof Date ? record.added_on : new Date(record.added_on * 1000);
                  return date.toLocaleDateString();
                }
              }
            />
          }
          medium={
            <Datagrid>
              <TextField source="name" />
              <DeviceTypeComponent source="type" />
              <TimeStampDateField source="added_on" showTime={true}/>
              <ShowButton />
            </Datagrid>
          }
        />
      </List>
    );
  }
}

export default DevicesList;
