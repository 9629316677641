import React, { Fragment, useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    FormWithRedirectSave,
    ResourceComponentProps,
    FormInputProps,
    CreateButton,
    SimpleForm,
    CreateProps,
    useRefresh,
    Toolbar,
    useTranslate
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const QuickAddDeviceButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate('devices');
    const notify = useNotify();
    const refresh = useRefresh();
    // const form = useForm();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async (values: any) => {
            create(
                { payload: { data: values }},
                {
                    onSuccess: (payload: any) => {
                        setShowDialog(false);
                        // Update the comment form to target the newly created post
                        // Updating the ReferenceInput value will force it to reload the available posts
                        // form.change('post_id', data.id);
                    },
                    onFailure: (error: { message: string; }) => {
                        notify(error.message, 'error');
                    }
                }
            );
    };

    return (
        <>
            <Button onClick={handleClick} label="ra.action.create">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Add Device"
            >
                <DialogTitle>Add Device</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        resource="posts"
                        // We override the redux-form onSubmit prop to handle the submission ourselves
                        save={handleSubmit}
                        // We want no toolbar at all as we have our modal actions
                        toolbar={<QuickDeviceCreateButtonToolbar onCancel={handleCloseClick} />}
                    >
                        <TextInput source="code" validate={required()} />
                        <TextInput source="name" validate={required()} />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

const QuickDeviceCreateButtonToolbar = ( { onCancel, ...props }: any ) => {
    return (
        <Toolbar {...props}>
            <SaveButton submitOnEnter={true} />
            <CancelButton onClick={onCancel} />
        </Toolbar>
    );
}

const CancelButton = ( props:any ) => {
    const translate = useTranslate();
    return (
        <Button label={translate('ra.action.cancel')} {...props}>
            <IconCancel />
        </Button>
    );
}

export default QuickAddDeviceButton;