import { AppConfig } from "../config"
import { AuthProvider, HttpError, UserIdentity } from 'react-admin';

class AppAuthProvider implements AuthProvider {
    async login (params: any) {
        const request = new Request(AppConfig.apiUrl + '/api/user/authenticate', {
            method: 'POST',
            credentials: "include",
            body: JSON.stringify({ 
                login: params.username,
                password: params.password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                localStorage.setItem('authToken', JSON.stringify(response.authToken));
                localStorage.setItem('user', JSON.stringify(response.user));
            })
            .catch(() => {
                throw new Error('Network error')
            });
    }
    async checkError (error: any) {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('user');
            return Promise.reject({ message: false });
        }
        return Promise.resolve(); 
    }
    
    async checkAuth (params: any) {
        return localStorage.getItem('authToken')
        ? Promise.resolve()
        : Promise.reject({ message: 'login.required' });
    }

    async logout (params: any) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        return Promise.resolve();
    }

    async getIdentity() {
        try {
            let user: any = localStorage.getItem('user');
            const identity: UserIdentity = {
                 id: user.login,
                 fullName: user.login
             };
            return Promise.resolve(identity);
        } catch (error) {
            return Promise.reject(error);
        }        
    }

    async getPermissions(params: any) {
        // const role = localStorage.getItem('permissions');
        // return role ? Promise.resolve(role) : Promise.reject();
        return Promise.reject();
    }
    
};

var authProvider = new AppAuthProvider();
export default authProvider;// { authProvider, AppAuthProvider }
